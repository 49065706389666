<template>
    <div class="w-blog-entry-h">
        <a class="w-blog-entry-link">
            <h2>{{ project.Title }}</h2>
        </a>
        <div class="w-blog-entry-body">
            <div>
                <i class="fa fa-user"></i>
                &nbsp;
                <b>role:</b>
                {{ project.Role }}
            </div>
            <div>
                <i class="fa fa-clock-o"></i>
                &nbsp;
                <span class="w-blog-entry-meta-date-month">
                    <b>started:</b>
                    {{ project["Started On"] }}
                </span>
                &nbsp; / &nbsp;
                <span class="w-blog-entry-meta-date-month">
                    <b>finished:</b>
                    {{ project["Finished On"] }}
                </span>
            </div>
            <div v-show="project.Techs">
                <i class="fa fa-gears"></i>&nbsp;
                <ul class="project-tags">
                    <li v-for="tag in project.Techs" :key="tag">
                        <a href="javascript:void(0);">{{ tag }}</a>
                    </li>
                </ul>
            </div>
            <div v-html="textToHtml" class="w-blog-entry-short"></div>
            <div v-show="project.Links">
                <a
                    v-for="link in links"
                    :key="link.name"
                    :href="link.url"
                    class="project-link"
                    target="_blank"
                >
                    <i class="fa fa-external-link"></i>
                    {{ link.name }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import { marked } from 'marked';

export default {
    name: "ProjectItem",
    props: ["project"],
    data() {
        return this.project;
    },
    computed: {
        links() {
            var value = this.project.Links;
            if (Array.isArray(value)) {
                var result = [];
                for (var i in value) {
                    if (typeof value[i] === "string") {
                        result.push({ name: "Website", url: value[i] });
                    }
                    else {
                        result.push({ name: value[i].name, url: value[i].url });
                    }
                }
                return result;
            }
            return [{ name: "Website", url: value }];
        },
        textToHtml() {
            return marked(this.project.Description);
        }
    }
}
</script>

<style scoped>
div.w-blog-entry-short i {
    font-size: 20px;
}
ul.project-tags {
    display: inline;
    margin: 0;
    list-style: none;
}
ul.project-tags li {
    display: inline;
    padding-right: 2px;
}
ul.project-tags li::after {
    content: ",";
}
ul.project-tags li:last-child::after {
    content: "";
}
ul.project-tags li a {
    cursor: default;
}
a.project-link {
    padding-right: 10px;
}
p {
    line-height: normal;
    margin-bottom: 10px;
}
</style>