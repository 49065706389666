<template>
    <h2>Hi I'm Velyo</h2>

    <p>
        <em>
            I bring over <span experience>{{ years }}&#x2B;</span> years of experience in programming and software development, 
            working across a diverse range of technologies and maintaining a solid understanding of the latest market trends. <br />
            This extensive background provides me with a strong foundation in both software development and team leadership.
        </em>
    </p>

    <p>
        Fueled by my passion for software development, I have become a founder and actively contribute to open-source projects. <br/>
        I thrive as a collaborative team member, skilled at adapting to dynamic environments and evolving industry standards.
    </p>

    <p>
        Currently, I work as a Solution Architect at <a href="http://www.scalefocus.com/">ScaleFocus</a>, 
        collaborating with an exceptional team on a variety of exciting projects.<br /> 
        Prior to my role at <a href="http://www.scalefocus.com/">ScaleFocus</a>, 
        I was Software Development Lead at <a href="https://www.mentormate.bg/">MentorMate-Bulgaria</a>, 
        where I focused on a long-term Java EE project.
    </p>

    <p>
        In my spare time, I contribute to several open-source projects, which you can explore on <a href="https://github.com/velyo">my GitHub account</a>.<br/>
        I reside in the ancient and beautiful city of <a href="http://en.wikipedia.org/wiki/Plovdiv" target="_blank">Plovdiv</a>, 
        <a href="http://en.wikipedia.org/wiki/Bulgaria" target="_blank">Bulgaria</a>,
        which was honored as the Bulgarian host of the <a href="http://plovdiv2019.eu/en">European Capital of Culture in 2019</a>.
    </p>

    <p>
        While I am passionate about programming, when I'm not coding, <br/>
        I enjoy spending quality time with my family, fishing, brewing beer, and playing soccer.
    </p>

    <teleport to="#pageTitle">Welcome</teleport>
    <teleport to="#pageSubTitle">About Me</teleport>
</template>

<script>
export default {
    name: "HomePage",
    data() {
        var millis = Date.now();
        var date = new Date(millis);
        return {
            years: date.getUTCFullYear() - 1998
        }
    },
};
</script>